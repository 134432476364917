/** @define ContentStage */
$componentClass: '.ContentStageSlim';

#{$componentClass} {
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &--textLight {
      color: $color-white;
    }

    &--textDefault {
      color: $color-black;
    }

    #{$componentClass}-textBox {
      padding: 3rem 1.5rem 1.5rem;
      text-align: center;
      font-family: $font-family-regular-redesign;

      #{$componentClass}-headline {
        margin: 0 0 0.5rem;
        font-size: $h-l_font-size;
        line-height: $h-l_line-height;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        &--mobile {
          display: block;
        }

        &--desktop {
          display: none;
        }
      }

      #{$componentClass}-subline {
        text-align: center;

        &--mobile {
          display: block;
        }

        &--desktop {
          display: none;
        }

        span {
          font-size: $h-s_font-size;
          line-height: $h-s_line-height;
          font-weight: $font-weight-light;
          display: inline;
          margin: 0 4px;
        }
      }
    }

    #{$componentClass}-imageContainer {
      #{$componentClass}-image {
        width: 100%;
        height: auto;

        &--mobile {
          display: block;
        }

        &--desktop {
          display: none;
        }
      }

      .StageLinkButton {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(calc(-50% - 24px));
      }
    }
  }
}

@media screen and (min-width: $breakpoint_s-to-m) {
  #{$componentClass} {
    &-item {
      #{$componentClass}-textBox {
        #{$componentClass}-headline {
          &--desktop {
            display: block;
          }

          &--mobile {
            display: none;
          }
        }

        #{$componentClass}-subline {

          &--desktop {
            display: block;
          }

          &--mobile {
            display: none;
          }

          span {
            display: block;
            margin: 0;
          }
        }
      }

      #{$componentClass}-imageContainer {
        #{$componentClass}-image {
          width: 100%;
          height: 100%;

          &--desktop {
            display: block;
          }

          &--mobile {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint_m-to-l) {
  #{$componentClass} {
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    margin: 16px;

    &-item {
      #{$componentClass}-textBox {
        #{$componentClass}-headline {
          font-size: $h-xl_font-size;
          line-height: $h-xl_line-height;
        }

        #{$componentClass}-subline {
          span {
            font-size: $h-m_font-size_new;
            line-height: $h-m_line-height_new;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint_l-to-xl) {
  #{$componentClass} {
    &-item  #{$componentClass}-imageContainer {
      width: calc($breakpoint_l-to-xl - 32px);
      height: auto;
    }
    &--splitStage &-item #{$componentClass}-imageContainer {
      width: calc($breakpoint_l-to-xl / 2 - 48px);
      height: auto;
    }
  }
}


