/** @define FormOptionBullet */
$componentClass: '.FormOptionBullet';
.FormOptionBullet {
  position: relative;
  display:inline-block;
  &-input {
    position: absolute;
    top: 1px;
    left: 2px;
    background: transparent;
    border: none;
    appearance: none;
    opacity: 0;
  }
  &-labelInner {}
  &-input + &-label {
    cursor: pointer;
  }
  &-input:checked + &-label::before,
  &-input:not(:checked) + &-label::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    border: 1px solid transparent;
    transition: border $timing-duration $timing-easing;
  }

  &-input:checked + &-label::before {
    border-color: $color-primary;
  }

  &-input:hover + &-label::before {
    border-color: $color-gray100;
  }

  &-input:not(:checked):focus + &-label::before {
    border-color: $color-gray100;
  }

  &-input:disabled + &-label::before {
    border-width: 1px;
    border-color: $color-gray15;
  }

  &-input:disabled + &-label &-labelInner {
    opacity: .5;
  }

  &-labelInner{
    display: inline-block;
    margin:rem-calc(3);
    width: rem-calc(12);
    height:rem-calc(12);
    border-radius: rem-calc(12);
    border: 1px solid #ccc;
    background: $color-white;
  }

  &--filled {
    #{$componentClass}-input:checked + #{$componentClass}-label::before,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label::before {
      border: 5px solid transparent;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before {
      border-color: $color-primary;
    }

    #{$componentClass}-input:hover + #{$componentClass}-label::before {
      border-color: $color-gray100;
    }

    #{$componentClass}-input:disabled + #{$componentClass}-label::before {
      border-width: 5px;
      border-color: $color-gray15;
    }

    #{$componentClass}-input:disabled + #{$componentClass}-label &-labelInner {
      opacity: .5;
    }
  }

  &.is-disabled {
    #{$componentClass}-input:hover + #{$componentClass}-label::before {
      border-color: $color-gray15;
    }

    #{$componentClass}-input:disabled + #{$componentClass}-label #{$componentClass}-labelInner {
      opacity: .5;
    }
  }

  &.is-selected {
    &-input:checked + &-label::before {
      border-color: $color-primary;
    }
  }
}
