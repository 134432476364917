@font-face {
  font-family: 'Interstate-Regular-Legacy';
  src: url('/fonts/Interstate-Light.woff2') format('woff2'),
  url('/fonts/Interstate-Light.woff') format('woff'),
  url('/fonts/Interstate-Light.eot') format('embedded-opentype'),
  url('/fonts/Interstate-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Regular-Legacy';
  src: url('/fonts/Interstate-Regular.woff2') format('woff2'),
  url('/fonts/Interstate-Regular.woff') format('woff'),
  url('/fonts/Interstate-Regular.eot') format('embedded-opentype'),
  url('/fonts/Interstate-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Regular';
  src: url('/fonts/Interstate-Light.woff2') format('woff2'),
       url('/fonts/Interstate-Light.woff') format('woff'),
       url('/fonts/Interstate-Light.eot') format('embedded-opentype'),
       url('/fonts/Interstate-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Regular';
  src: url('/fonts/Interstate-Regular.woff2') format('woff2'),
       url('/fonts/Interstate-Regular.woff') format('woff'),
       url('/fonts/Interstate-Regular.eot') format('embedded-opentype'),
       url('/fonts/Interstate-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Regular';
  src: url('/fonts/Interstate-Bold.woff2') format('woff2'),
       url('/fonts/Interstate-Bold.woff') format('woff'),
       url('/fonts/Interstate-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Condensed';
  src: url('/fonts/Interstate-LightCondensed.woff2') format('woff2'),
       url('/fonts/Interstate-LightCondensed.woff') format('woff'),
       url('/fonts/Interstate-LightCondensed.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Condensed';
  src: url('/fonts/Interstate-BoldCondensed.woff2') format('woff2'),
       url('/fonts/Interstate-BoldCondensed.woff') format('woff'),
       url('/fonts/Interstate-BoldCondensed.eot') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}
