/** @define FormOptionGroup */
$componentClass: '.FormOptionGroup';
.FormOptionGroup{

  border: none;

  &-content {}

  &-label {
    margin-top:0;
  }

  &-label.is-disabled {
    color: $color-gray50;
    transition: color $timing-duration $timing-easing;
  }

  &--centered{
    text-align:center;
  }

  &:has(.FormOptionBullet:focus-within) {
    border: solid;
  }
}
