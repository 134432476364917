/** @define StageLinkButton */
$componentClass: '.StageLinkButton';

#{$componentClass} {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: calc(100% - 48px);
  max-width: 337px;
  min-width: 190px;
  background-color: $color-white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 24px;

  &--invisible {
    background-color: transparent;
    box-shadow: none;

    #{$componentClass}-inner {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    border: none;
    border-radius: 6px;
    font-family: $font-family-regular-redesign;
    font-weight: $font-weight-bold;
    color: $color-black;
    cursor: pointer;

    &--primary {
      background: $gradient-primary;
    }

    &--secondary {
      background: $gradient-secondary;
    }
  }
}
